<template>
  <div>
    <highcharts class="chartOrder" :options="chartOrder"></highcharts>
  </div>
</template>

<script>
    import { Chart } from 'highcharts-vue';
    import axios from "axios";

    export default {
        name: "ReportOrder",
        metaInfo() {
            return {
                title: "",
                dataTable : []
            };
        },
        components: {
            highcharts: Chart
        },
        props: {
            time: String,
        },
        data() {
            return {
                canSubmit: true,
                chartOrder: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: '',
                        align: ''
                    },
                    subtitle: {
                        text:
                            '',
                        align: ''
                    },
                    xAxis: {
                        categories: [],
                        crosshair: true,
                        accessibility: {
                            description: 'Countries'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    tooltip: {
                        valueSuffix: ' '
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [
                        {
                            name: 'Đơn hoàn thành ',
                            data: [],
                            color: '#CEE7FF',
                        },
                        {
                            name: 'Đơn đặt cọc',
                            data: [],
                            color: '#569AF2',
                        },
                    ]
                },
            };
        },
        computed: {},
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
              let filter = {
                type: this.time,
              };
              axios.get("report/report-order-client", {params : filter}).then((response) => {
                switch (response.code) {
                  case 200:
                    this.dataTable = response.data;
                    this.chartOrder.series[0].data = this.dataTable.map(item => {
                      return [parseFloat(item.total_order_done)];
                    });
                    this.chartOrder.series[1].data = this.dataTable.map(item => {
                      return [parseFloat(item.total_order_deposited)];
                    });
                    this.chartOrder.xAxis.categories = this.dataTable.map(item => {
                      return (item.name);
                    });
                    break;
                  default:
                    this.$notify.error({
                      title: "Error",
                      message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                    });
                    break;
                }
              });
            }
        },
        watch: {
            'time':function () {
                this.fetchData();
            },
        },
    };
</script>

<style lang="css" scoped>

</style>
<style>
  .chartOrder .highcharts-credits{
    display: none;
  }
</style>
