<template>
  <div>
    <highcharts class="chartFinance" :options="chartFinance"></highcharts>
  </div>
</template>

<script>
    import {Chart} from 'highcharts-vue';
    import axios from "axios";

    export default {
        name: "ReportFinance",
        metaInfo() {
            return {
                title: "",
            };
        },
        components: {
            highcharts: Chart
        },
        props: {
            time: String,
        },
        data() {
            return {
                canSubmit: true,
                chartFinance: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: '',
                        align: ''
                    },
                    subtitle: {
                        text:
                            '',
                        align: ''
                    },
                    xAxis: {
                        categories: [],
                        crosshair: true,
                        accessibility: {
                            description: 'Countries'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    tooltip: {
                        valueSuffix: ' '
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [
                        {
                            name: 'Tổng nạp',
                            data: [],
                            color: '#26B01C',
                        },
                        {
                            name: 'Tổng thanh toán',
                            data: [],
                            color: '#2196F3',
                        },
                        {
                            name: 'Rút',
                            data: [],
                            color: '#C20000',
                        },
                    ]
                },

            };
        },
        computed: {},
        mounted() {
            this.fetchData();
        },
        methods: {
          fetchData() {
            let filter = {
              type: this.time,
            };
            axios.get("report/report-financial", {params : filter}).then((response) => {
              switch (response.code) {
                case 200:
                  this.dataTable = response.data;
                  this.chartFinance.series[0].data = this.dataTable.map(item => {
                    return [parseFloat(item.total_deposit)];
                  });
                  this.chartFinance.series[1].data = this.dataTable.map(item => {
                    return [parseFloat(item.total_payment)];
                  });
                  this.chartFinance.series[2].data = this.dataTable.map(item => {
                    return [parseFloat(item.total_withdrawal)];
                  });
                  this.chartFinance.xAxis.categories = this.dataTable.map(item => {
                    return (item.name);
                  });
                  break;
                default:
                  this.$notify.error({
                    title: "Error",
                    message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                  });
                  break;
              }
            });
          }
        },
        watch: {
            'time':function () {
                this.fetchData();
            },
        },
    };
</script>

<style lang="css" scoped>

</style>
<style>
  .chartFinance .highcharts-credits{
    display: none;
  }
</style>
