<template>
  <div class="component-menu-left d-flex flex-column">
    <div
      class="item"
      @click="showHome()"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'home-index', params: {} }"
      >
        <img src="@/static/icon/menu-left/home.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'home-index', params: {} }"
      >
        <span>Trang chủ</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[full_path == '/cart-create' ? 'active' : '']"
      @click="nextRoute('cart-create', '/cart-create')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'cart-create', params: {} }"
      >
        <img src="@/static/icon/menu-left/cart.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'cart-create', params: {} }"
      >
        <span>Giỏ hàng</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[full_path == '/order-create' ? 'active' : '']"
      @click="nextRoute('order-create', '/order-create')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'order-create', params: {} }"
      >
        <img src="@/static/icon/menu-left/order.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'order-create', params: {} }"
      >
        <span>Tạo đơn order</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[full_path == '/consignment-create' ? 'active' : '']"
      @click="nextRoute('consignment-create', '/consignment-create')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'consignment-create', params: {} }"
      >
        <img src="@/static/icon/menu-left/create-order.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'consignment-create', params: {} }"
      >
        <span>Tạo đơn ký gửi</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[
        full_path == '/order-list' ||
        path == 'order-list' ||
        path == 'consignment-list' ||
        path == 'consignment-detail'
          ? 'active'
          : '',
      ]"
      @click="nextRoute('order-list', '/order-list')"
      @mouseover="menuHover = 'order'"
      @mouseleave="menuHover = ''"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'order-list', params: {} }"
      >
        <img src="@/static/icon/menu-left/order.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'order-list', params: {} }"
      >
        <span>Đơn hàng</span>
      </router-link>
      <div class="sub-menu" v-if="menuHover == 'order'">
        <div
          class="sub-menu-item"
          @click.stop="nextRouteSub('order-list', '/order-list')"
        >
          <span>Tất cả đơn order</span>
        </div>
        <div
          class="sub-menu-item"
          @click.stop="nextRouteSub('consignment-list', '/consignment-list')"
        >
          <span>Tất cả đơn kí gửi</span>
        </div>
      </div>
    </div>
    <div
      class="item"
      :class="[
        full_path == '/pack-list' || path == 'pack-list' ? 'active' : '',
      ]"
      @click="nextRoute('pack-list', '/pack-list')"
      @mouseover="menuHover = 'pack'"
      @mouseleave="menuHover = ''"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'pack-list', params: {} }"
      >
        <img src="@/static/icon/menu-left/pack.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'pack-list', params: {} }"
      >
        <span>Kiện hàng</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[
        full_path == '/delivery-list' || path == 'delivery-list'
          ? 'active'
          : '',
      ]"
      @click="nextRoute('delivery-list', '/delivery-list')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'delivery-list', params: {} }"
      >
        <img src="@/static/icon/menu-left/giaohang.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'delivery-list', params: {} }"
      >
        <span>Giao hàng</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[
        full_path == '/complain-list' ||
        path == 'complain-list' ||
        path == 'complain-create'
          ? 'active'
          : '',
      ]"
      @click="nextRoute('complain-list', '/complain-list')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'complain-list', params: {} }"
      >
        <img src="@/static/icon/menu-left/khieunai.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'complain-list', params: {} }"
      >
        <span>Khiếu nại</span>
      </router-link>
    </div>
    <div
      class="item"
      :class="[
        full_path == '/supplier-list' || path == 'supplier-list'
          ? 'active'
          : '',
      ]"
      @click="nextRoute('supplier-list', '/supplier-list')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'supplier-list', params: {} }"
      >
        <img src="@/static/icon/menu-left/cungcap.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'supplier-list', params: {} }"
      >
        <span>Nhà cung cấp</span>
      </router-link>
    </div>
    <!-- <div
      class="item"
      :class="[
        full_path == '/delivery-list' || path == 'delivery-list'
          ? 'active'
          : '',
      ]"
      @click="nextRoute('delivery-list', '/delivery-list')"
    >
      <img src="@/static/icon/menu-left/giaohang.svg" />
      <span>Giao hàng</span>
    </div> -->
    <div
        class="item"
        :class="[
        full_path == '/check-fees' || path == 'check-fees'
          ? 'active'
          : '',
      ]"
        @click="nextRoute('check-fees', '/check-fees')"
    >
      <img src="@/static/icon/menu-left/tra-cuoc.svg"/>
      <span>Tra cước</span>
    </div>
    <div
      class="item"
      :class="[
        full_path == '/transaction-list' || path == 'transaction-withdraw'
          ? 'active'
          : '',
      ]"
      @mouseover="menuHover = 'transaction'"
      @mouseleave="menuHover = ''"
    >
      <img src="@/static/icon/menu-left/vidientu.svg" />
      <span>Tài khoản</span>
      <div class="sub-menu" v-if="menuHover == 'transaction'">
        <div
          class="sub-menu-item"
          @click.stop="nextRouteSub('transaction-list', '/transaction-list')"
        >
          <span>Lịch sử giao dịch</span>
        </div>
        <!--  -->
        <div
          class="sub-menu-item"
          @click.stop="showQrCodeBank = true"
        >
          <span>Nạp tiền</span>
        </div>
        <!--  -->
        <div
          class="sub-menu-item"
          @click.stop="
            nextRouteSub('transaction-withdraw', '/transaction-withdraw')
          "
        >
          <span>Rút tiền</span>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      class="item"
      :class="[
        full_path == '/search-list' || path == 'search-list'
          ? 'active'
          : '',
      ]"
      @click="nextRoute('search-list', '/search-list')"
    >
      <router-link
        class="spanLink"
        :to="{ name: 'search-list', params: {} }"
      >
        <img src="@/static/icon/menu-left/search.svg" />
      </router-link>
      <router-link
        class="spanLink"
        :to="{ name: 'search-list', params: {} }"
      >
        <span>Tìm kiếm sản phẩm</span>
      </router-link>
    </div>

    <!-- pop up nạp tiền  -->
    <el-dialog
      title="Nạp tiền vào ví"
      top="50px"
      width="600px"
      custom-class="transaction-list-dialog-882"
      :close-on-click-modal="false"
      :visible.sync="showQrCodeBank"
    >
      <QrCodeBank
        v-if="showQrCodeBank"
        :totalMoneyBank="false"
        @closeQrCodeBank="closeQrCodeBankParent"
      />
    </el-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QrCodeBank from "@/components/QrCodeBank";
export default {
  name: "LayoutMenuLeft",
  data() {
    return {
      showText: true,
      menuHover: "",
      // Phần nạp tiền
      showQrCodeBank: false,
    };
  },
  computed: {
    ...mapGetters(["path", "full_path"]),
    ...mapGetters("account", ["accountInfo", "configList"]),
  },
  components: {
    QrCodeBank,
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),

    showFee() {
      this.$message({
        message: 'Hệ thống đang nâng cấp.',
        type: 'success',
      });
    },

    // Đóng nạp tiền
    closeQrCodeBankParent() {
      this.showQrCodeBank = false;
    },
    showHome() {
      this.$router.push({ name: 'home-index' });
    },
    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        this.$router.push({ name: name });
      }
      this.getAccountInfo();
    },
    nextRouteSub(name, full_path) {
      this.menuHover = "";
      if (this.full_path !== full_path) {
        this.$router.push({ name: name });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.component-menu-left .item {
  border-bottom: 1px solid #cedceb;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 2px;
  position: relative;
}
.component-menu-left .item:hover {
  background-color: #d6e8fb;
  transition: all 0.3s;
}
.component-menu-left .item img {
  width: 25px;
}
.component-menu-left .item span {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  line-height: 15px;
}
.component-menu-left .item.active {
  background-color: #1d60a6;
  color: #fff;
}

.sub-menu {
  position: absolute;
  width: 220px;
  min-height: 50px;
  background: #fff;
  top: 0px;
  left: 65px;
  z-index: 1000;
  padding-left: 6px;
}
.sub-menu-item {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #ccc;
  background: #1d60a6;
  padding-left: 10px;
  color: #fff;
}
.sub-menu-item:hover {
  opacity: 0.8;
}
</style>
<style>
.transaction-list-dialog-882 .el-dialog__header {
  font-weight: 700;
  font-size: 24px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
}
.transaction-list-dialog-882 .el-dialog__title {
  color: #1771c1;
}
.transaction-list-dialog-882 .el-dialog__body {
  padding: 10px 20px 20px 20px;
}
</style>
