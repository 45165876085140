import ls from "local-storage";
import store from "@/store";

const requireAuthByToken = (to, from, next) => {
  if (typeof to.query.redirect_token !== 'undefined') {
    const token = ls.get("authen", "");
    if (token) {
      ls.remove("authen");
    }

    // Giải mã
    const encryptedText = to.query.redirect_token ?? "";
    const responseDataToken = JSON.parse(atob(encryptedText));

    // setCookie mới từ link redirect
    ls("authen", responseDataToken);
    const responseData = JSON.stringify(responseDataToken.data);

    var dateNow = new Date();
    dateNow.setTime(dateNow.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + dateNow.toUTCString();
    document.cookie = "x-client" + "=" + responseData + ";" + expires + ";path=/";
    document.cookie = "info" + "=" + responseData + ";" + expires + ";path=/";
    next({
      path: "/cart-create",
    });
  }
};

const requireAuth = (to, from, next) => {
  let path = to.path;
  let split = path.split("/");
  store.dispatch("setPath", split[1]);
  store.dispatch("setFullPath", path);
  let token = ls.get("authen");
  if (!token) {
    next({
      path: "/login",
    });
  } else {
    next();
  }
};

const requireAuthV2 = (to, from, next) => {
  let path = to.path;
  let split = path.split("/");
  store.dispatch("setPath", split[1]);
  store.dispatch("setFullPath", path);
  next();
};

export default {
  requireAuthByToken,
  requireAuth,
  requireAuthV2,
};
