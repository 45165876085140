<template>
  <div class="component-header-top">
    <div class="component-header-top-item d-flex justify-content-between align-items-center">
      <!-- LOGO, Ô SEARCH -->
      <div class="d-flex align-items-center">
        <!-- LOGO -->
        <div class="logo" @click="nextHome()">
          <img src="@/static/images/logo/hvlogo.svg"/>
        </div>
        <!-- Ô SEARCH -->
        <div
          v-if="!notShowSearchPages.includes(path)"
          :class="{
            'seach-china d-flex align-items-center': true,
            'add-border-99': showSelectMall
          }"
        >
          <!-- CHỌN PLATFORM -->
          <div
            class="select-mall-head d-flex align-items-center"
            @click.stop="showSelectMall = true" @mouseleave="mouseLeaveSelectMall"
          >
            <div class="image">
              <img src="@/static/images/header-top/taobao.png" v-if="key_mall == 'sync_taobao'"/>
              <img src="@/static/images/header-top/1688.png" v-if="key_mall == 'sync_1688'"/>
            </div>
            <img src="@/static/icon/header-top/down2.svg"/>
            <div class="sub-select-mall" v-if="showSelectMall">
              <div class="image" @click.stop="changeMall('sync_taobao')">
                <img src="@/static/images/header-top/taobao.png"/>
              </div>
              <div class="image" @click.stop="changeMall('sync_1688')">
                <img src="@/static/images/header-top/1688.png"/>
              </div>
            </div>
          </div>
          <!-- DANH SÁCH DANH MỤC HÀNG HÓA -->
          <div class="list-category">
            <nav class="nav-menu-head">
              <ul>
                <li class="icon-down">
                  <i class="el-icon-caret-bottom"></i>
                  <ul class="menu-lv-item">
                    <li v-for="item in dataListCate" :key="item.id" class="nav-lv">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ item.name }}</span>
                        <span><i v-if="item.child.length > 0" class="el-icon-arrow-right"></i></span>
                      </div>
                      <ul>
                        <li v-for="i in item.child" :key="i.id" class="nav-lv" @click="showKeySearch(i)">
                          <div class="d-flex justify-content-between align-items-center">
                            <span>{{ i.name }}</span>
                            <span><i v-if="i.child.length > 0" class="el-icon-arrow-right"></i></span>
                          </div>
                          <ul>
                            <li v-for="y in i.child" :key="y.id" class="nav-lv" @click="showKeySearch(y)">
                              <span>{{ y.name }}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <!-- Ô SEARCH -->
          <el-input
            v-model="search" type="text"
            placeholder="Chào bạn, bạn muốn tìm gì hôm nay ?"
            @keyup.enter.native="searchProduct()"
          ></el-input>
          <!-- NUT SEARCH -->
          <div class="seach-button" @click=searchProduct()>
            <span class="btn-18">
              <img src="@/static/icon/header-top/search.svg"/>
            </span>
          </div>
        </div>
      </div>

      <!-- HOTLINE, TỶ GIÁ, SỐ DƯ, GIỎ HÀNG, NOTI -->
      <div class="d-flex justify-content-between align-items-center">
        <!-- HOTLINE, TỶ GIÁ, SỐ DƯ -->
        <div class="user-ratio d-flex justify-content-between align-items-center">
          <img class="ratio-image" src="@/static/icon/header-top/phone.svg"/>
          <span>Hotline:</span>
          <span class="number-td-1202">1900 6825.</span>
        </div>
        <div class="user-ratio d-flex justify-content-between align-items-center">
          <img class="ratio-image" src="@/static/icon/header-top/ratio.svg"/>
          <span>Tỷ giá:</span>
          <span class="number-td-1202">{{ configList.exchange_rate | vnd2 }}  đ / ¥</span>
        </div>
        <div class="user-balance d-flex justify-content-between align-items-center">
          <span>Số dư:</span>
          <span class="number-td-1202">{{ accountInfo.e_wallet | vnd }}</span>
          <img class="down" src="@/static/icon/header-top/money.svg"/>
        </div>
        <!-- CART, NOTI, BELL ICONS -->
        <div class="noti-global" @click="nextCart()">
          <img class="down" src="@/static/icon/header-top/cart.svg"/>
          <span class="count">{{ countCart }}</span>
        </div>
        <div class="noti-global">
          <img class="down" src="@/static/icon/header-top/mail.svg"/>
          <span class="count">0</span>
        </div>
        <div class="noti-global" @click="showNoti()">
          <img class="down" :class="[accountInfo.total_noti.all > 0 ? 'rung' : '']"
               src="@/static/icon/header-top/noti.svg"/>
          <span class="count" v-if="accountInfo.total_noti.all > 0">{{ accountInfo.total_noti.all }}</span>
        </div>

        <!-- POPUP NOTI -->
        <div class="content-noti">
          <el-dialog :visible.sync="dialogNoti">
            <div class="header-noti justify-content-between">
              <div>
                <span class="header-left pl-2" v-if="tabActive == 3">Kiện hàng</span>
                <span class="header-left pl-2" v-if="tabActive == 5">Khiếu nại</span>
                <span class="header-left pl-2" v-if="tabActive == 12">Thông báo mới</span>
                <span class="header-left pl-2" v-if="tabActive == 8">Tài chính</span>
                <span class="header-left pl-2" v-if="tabActive == 9">Ưu đãi</span>
                <span class="header-left pl-2" v-if="tabActive == 11">Góp ý</span>
                <span class="header-left pl-2" v-if="tabActive == 2">Đơn hàng</span>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex aligin-itemsc-center" @click="clickAllNoti()">
                  <img class="pr-1" src="@/static/icon/noti/tick.svg" alt=""/>
                  <span class="acticon-click">Đánh dấu đã đọc</span>
                </div>
                <img @click="dialogNoti = false" class="pl-2 acticon-click" src="@/static/icon/noti/close.svg" alt=""/>
              </div>
            </div>
            <div class="body-noti">
              <el-row :gutter="24">
                <el-col :span="4">
                  <div class="d-flex flex-column">
                    <div class="icon-left-noti" :class="tabActive == 12 ? 'icon-active' : ''" @click="changeTab(12)">
                      <el-badge :value="accountInfo.total_noti.news" :max="99" class="item">
                        <img v-if="tabActive == 12" src="@/static/icon/noti/tbmoiac.svg"/>
                        <img v-else src="@/static/icon/noti/tbmoi.svg"/>
                      </el-badge>
                    </div>
                    <div class="icon-left-noti" :class="tabActive == 2 ? 'icon-active' : ''" @click="changeTab(2)">
                      <el-badge :value="accountInfo.total_noti.order" :max="99" class="item">
                        <img v-if="tabActive == 2" src="@/static/icon/menu-left/order.svg"/>
                        <img v-else src="@/static/icon/menu-left/order.svg"/>
                      </el-badge>
                    </div>
                    <div class="icon-left-noti" :class="tabActive == 8 ? 'icon-active' : ''" @click="changeTab(8)">

                      <el-badge :value="accountInfo.total_noti.finance" :max="99" class="item">
                        <img v-if="tabActive == 8" src="@/static/icon/noti/taichinhac.svg"/>
                        <img v-else src="@/static/icon/noti/taichinh.svg"/>
                      </el-badge>


                    </div>
                    <div class="icon-left-noti" :class="tabActive == 3 ? 'icon-active' : ''" @click="changeTab(3)">
                      <el-badge :value="accountInfo.total_noti.package" :max="99" class="item">
                        <img v-if="tabActive == 3" src="@/static/icon/noti/kienhangac.svg"/>
                        <img v-else src="@/static/icon/noti/kienhang.svg"/>
                      </el-badge>
                    </div>
                    <div class="icon-left-noti" :class="tabActive == 5 ? 'icon-active' : ''" @click="changeTab(5)">
                      <el-badge :value="accountInfo.total_noti.complain" :max="99" class="item">
                        <img v-if="tabActive == 5" src="@/static/icon/noti/khieunaiac.svg"/>
                        <img v-else src="@/static/icon/noti/khieunai.svg"/>
                      </el-badge>
                    </div>
                    <div class="icon-left-noti" :class="tabActive == 9 ? 'icon-active' : ''" @click="changeTab(9)">
                      <el-badge :value="accountInfo.total_noti.endow" :max="99" class="item">
                        <img v-if="tabActive == 9" src="@/static/icon/noti/uudaiac.svg"/>
                        <img v-else src="@/static/icon/noti/uudai.svg"/>
                      </el-badge>
                    </div>
                    <div class="icon-left-noti" :class="tabActive == 11 ? 'icon-active' : ''" @click="changeTab(11)">
                      <el-badge :value="accountInfo.total_noti.feedback" :max="99" class="item">
                        <img v-if="tabActive == 11" src="@/static/icon/noti/gopyac.svg"/>
                        <img v-else src="@/static/icon/noti/gopy.svg"/>
                      </el-badge>
                    </div>
                  </div>
                </el-col>
                <el-col :span="20" class="span19">
                  <div v-if="tabActive" v-loading="loading" class="loading-content">
                    <div v-if="dataNoti.length > 0">
                      <div v-for="item in dataNoti" :key="item.id.index">
                        <div class="content-right-noti" @click="seenNoti(item)"
                             :class="item.time_seen == 0 ? 'content-right-noti-active' : ''">
                          <span class="time-noti">{{ item.created_at }}</span>

                          <span v-if="item.group == 3" class="pl-1">
                          {{ 'Kiện hàng ' + item.data.pack_code + ' được chuyển sang trạng thái ' }}
                        </span>
                          <span v-if="item.group == 3" class="pl-1 status-pack">
                          {{ item.data.pack_status_txt }}
                        </span>

                          <span v-else class="pl-1">{{ item.content }}</span>
                        </div>
                      </div>
                      <infinite-loading @infinite="infiniteHandler"></infinite-loading>
                    </div>
                    <div v-else>
                      <div class="content-right-noti align-items-center noti-none">
                        <span>Không có thông báo</span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-dialog>
        </div>

        <div class="dropdown-account" @click="showMenuTop = true" @mouseleave="mouseLeave">
          <img v-if="accountInfo.avatar" :src="accountInfo.avatar" class="avatar"/>
          <img v-else class="avatar" src="@/static/icon/header-top/account.svg"/>
          <span class="name">
            {{ accountInfo.name }}
            <img class="down" src="@/static/icon/header-top/down.svg"/>
          </span>
          <ul v-if="showMenuTop" @mouseover="mouseOver" @mouseleave="mouseLeave">
            <router-link :to="{ name: 'account-profile', params: {} }" @click.native.stop="showMenuTop = false">
              <span>Thông tin tài khoản</span>
            </router-link>
            <router-link :to="{ name: 'account-password', params: {} }" @click.native.stop="showMenuTop = false">
              <span>Đổi mật khẩu</span>
            </router-link>
            <a @click.stop="showPopupChangeStaffRequest">
              <span>Yêu cầu thay đổi nhân viên</span>
            </a>
            <a class="divider"></a>
            <a @click.stop.stop="confirmLogout">
              <span>Thoát tài khoản</span>
            </a>
          </ul>
        </div>

        <div class="popupServiceChangeStaff">
          <el-dialog
            title="Yêu cầu thay đổi nhân viên"
            :visible.sync="dialogVisibleChangeStaff"
            :close-on-click-modal="false"
            width="600px"
          >
            <el-form>
              <el-form-item>
                <el-input
                  v-model="reasonContent"
                  autofocus
                  ref='reasonContentRef'
                  type="textarea"
                  placeholder="Lý do thay đổi của quý khách là gì?"
                  rows="8"
                  max-rows="8"
                />
              </el-form-item>
              <el-form-item label="Tên người bạn muốn chuyển (không bắt buộc)">
                <el-input v-model="staffName" placeholder="Nhập tên nhân viên"/>
              </el-form-item>
            </el-form>

            <template #footer>
              <span class="dialog-footer">
                <el-button type="info" @click="dialogVisibleChangeStaff = false">ĐÓNG</el-button>
                <el-button type="success" @click="submitChangeStaffRequest()">
                  GỬI YÊU CẦU
                </el-button>
              </span>
            </template>

          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
  name: "LayoutHeaderTop",

  components: {
    InfiniteLoading,
  },

  props: {
    loadCart: Number,
  },

  data() {
    return {
      seach: "",
      showMenuTop: false,
      loading: false,
      timer: {},

      // Ô Seach
      notShowSearchPages: ['', 'search-list'],
      search: "",
      showSelectMall: false,
      mallActive: 'sync_taobao',

      // Cart
      countCart: 0,

      // pop-up noti
      dialogNoti: false,
      tabActive: 12,
      dataNoti: [],
      filter: {
        group: 1,
        page: 1,
        per_page: 10,
      },
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        last_page: 0,
      },
      listNoti: [],
      isLoadMore: false,
      tabLeft: 1,
      infiniteId: +new Date(),

      // ds category
      dataListCate: [],

      // Thay đổi nhân viên hỗ trợ
      reasonContent: "",
      staffName: "",
      dialogVisibleChangeStaff: false,
    };
  },

  computed: {
    ...mapGetters("account", ["accountInfo", "configList"]),
    ...mapGetters(["path", "full_path", "key_mall"]),
  },

  mounted() {
    this.fetchCart();
    this.socketNoti();
    this.fetchCategory();
  },

  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    ...mapActions("account", ["removeAccountInfo"]),

    showPopupChangeStaffRequest() {
      this.dialogVisibleChangeStaff = true;
    },
    submitChangeStaffRequest() {
      if (this.reasonContent === "") {
        this.$notify({
          title: "Thông báo",
          type: 'warning',
          message: "Vui lòng nhập lý do muốn thay đổi nhân viên",
        });
        this.$refs.reasonContentRef.focus();
        return false;
      }
      let params = {
        reason: this.reasonContent,
        staff_name_request: this.staffName
      };
      axios.post("customer/change-staff-request", params).then((response) => {
        switch (response.code) {
          case 200:
            this.dialogVisibleChangeStaff = false;
            this.reasonContent = "";
            this.staffName = "";
            this.$notify({
              title: "Thành công",
              message: "Gửi yêu cầu thành công, chúng tôi sẽ phản hồi sớm nhất có thể",
              type: "success",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    showKeySearch(i) {
      this.search = i.name
      if (this.search != "") {
        this.$router.push({
          name: "search-list",
          params: {
            key_search: i.name,
          },
        })
        this.search = "";
      }
    },
    // ds category
    fetchCategory() {
      axios.get("item/get-category").then((response) => {
        switch (response.code) {
          case 200:
            this.dataListCate = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    //
    searchProduct() {
      if (this.search != "") {
        this.$router.push({
          name: "search-list",
          params: {
            key_search: this.search,
          },
        })
        this.search = "";
      } else {
        this.$notify({
          title: "Vui lòng nhập đầy đủ thông tin",
          type: 'warning',
          message: "Vui lòng nhập từ khóa để tìm kiếm !",
        });
      }
    },
    socketNoti() {
      // Join vào room
      this.$socket.emit("join-to-room-code", this.accountInfo.code);
    },
    // xem từng noti
    seenNoti(item) {
      if (item.time_seen == 0) {
        axios.post("notification/click-noti/" + item.id).then((response) => {
          switch (response.code) {
            case 200:
              if (this.accountInfo.total_noti > 0) {
                this.accountInfo.total_noti = this.accountInfo.total_noti - 1;
              }
              this.dialogNoti = false;
              this.fetchDataNoti();
              this.showDetail(item)
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      } else {
        this.showDetail(item)
      }
    },
    // đánh dấu tất cả đã đọc
    clickAllNoti() {
      this.dataNoti.forEach((i) => {
        i.time_seen = 1
      });
      let data = {
        group: this.tabActive,
      }
      axios.post("notification/click-all-noti", data).then((response) => {
        switch (response.code) {
          case 200:
            this.getAccountInfo();
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // chuyển hướng đến màn chi tiết
    showDetail(item) {
      if (item.group == 8) {
        window.location.assign(location.href = "/transaction-list")
      }
      if (item.group == 3) {
        window.location.assign(location.href = "/pack-list/" + item.data.pack_id)
      }
      if (item.group == 5) {
        window.location.assign(location.href = "/complain-list/" + item.data.complain_id)
      }
      if (item.group == 2) {
        window.location.assign(location.href = "/order-list/" + item.data.order_id)
      }
      this.dialogNoti = false;
    },
    // load more
    infiniteHandler($state) {
      this.fetchDataNoti(true, $state);
    },
    // thông báo noti khách hàng
    showNoti() {
      this.dialogNoti = true;
      this.filter.page = 1;
      this.getAccountInfo();
      this.fetchDataNoti();
    },
    // đổi tab
    changeTab(tab) {
      this.tabActive = tab;
      this.tabLeft = tab;
      this.filter.page = 1;
      this.infiniteId += 1;
      this.fetchDataNoti();
    },
    fetchDataNoti(loading = true, $state = '') {
      if (loading) {
        this.loading = true;
      }
      let filter = {
        group: this.tabActive,
        page: this.filter.page,
        per_page: this.filter.per_page,
      }
      axios.get("notification", {params: filter}).then((response) => {
        this.loading = false;
        this.isLoadMore = false;
        switch (response.code) {
          case 200:

            if (this.tabActive == 12) {
              this.total_new = response.data.pagination.total
            }

            var dataItems = response.data.items;
            var dataPagination = response.data.pagination;


            if ($state) {
              if (this.filter.page > 1) {
                this.dataNoti.push(...dataItems);
              }

              this.filter.page += 1;
            } else {
              this.dataNoti = dataItems;
            }

            this.pagination.total = dataPagination.total;
            this.pagination.per_page = dataPagination.per_page;

            if ($state) {
              if (this.filter.page > dataPagination.last_page) {
                $state.complete();
              } else {
                $state.loaded();
              }
            }
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    //
    mouseLeave() {
      this.timer = setTimeout(() => {
        this.showMenuTop = false;
      }, 3000);
    },
    mouseOver() {
      clearTimeout(this.timer);
      this.showMenuTop = true;
    },
    confirmLogout() {
      this.$confirm("Xác nhận thoát tài khoản", "Warning", {
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy",
        type: "warning",
      })
        .then(() => {
          this.removeAccountInfo();
        })
        .catch(() => {
        });
    },
    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        this.$router.push({name: name});
      }
    },
    nextHome() {
      window.location.href = "https://hangve.com/";
    },

    mouseLeaveSelectMall() {
      this.showSelectMall = false;
    },
    changeMall(mall) {
      this.showSelectMall = false;
      this.mallActive = mall;
      store.dispatch("setKeyMall", mall);
    },

    fetchCart() {
      let tempCart;
      let tempCount;
      axios.get("cart/ext").then((response) => {
        switch (response.code) {
          case 200:
            tempCart = response.data;
            tempCount = 0;
            tempCart.forEach((item) => {
              tempCount += item.products.length;
            });
            this.countCart = tempCount;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    nextCart() {
      this.$router.push({
        name: "cart-create",
      });
    },
  },

  watch: {
    // thêm hoặc xóa sp giỏ hàng thì cập nhật lại số sp trên icon giỏ hàng
    loadCart: function () {
      this.fetchCart();
    },
    full_path: function () {
      this.fetchCart();
    }
  },

  sockets: {
    connect() {
      this.socketNoti();
    },
    "global-info": function (data) {
      if (data.channel == 'noti-admin') {
        this.accountInfo.total_noti.all += 1;
      }
    },
    "complain-info": function (data) {
        if (data.channel == 'complain-info') {
            this.accountInfo.total_noti.all += 1;
        }
      },
  }
};
</script>

<style lang="css" scoped>
.component-header-top {
  background-color: #436092;
  padding-top: 0px;
  height: 50px;
}

.component-header-top-line {
  background-color: #1d60a6;
  margin-bottom: 5px;
  height: 3px;
}

.component-header-top-item {
  height: 50px;
  padding: 0px 20px;
  background-color: #1d60a6;
}

.component-header-top-item .logo {
  cursor: pointer;
}

.dropdown-account {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 3px 0 3px;
  position: relative;
  height: 73px;
  color: #fff;
}

.dropdown-account .avatar {
  max-width: 100%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.dropdown-account ul {
  position: absolute;
  top: 31px;
  right: 0;
  width: 200px;
  background-color: #fff;
  padding: 5px 0 5px 0;
  z-index: 1000;
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
  margin: 42px 0 0;
}

.dropdown-account ul a {
  list-style-type: none;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  text-decoration: none;
  color: #2c3e50;
}

.dropdown-account ul a:hover {
  background-color: #f2f2f2;
}

.dropdown-account ul a.divider {
  height: 1px;
  background-color: #ccc;
  margin-bottom: 5px;
}

.noti-global {
  padding-right: 28px;
  position: relative;
  cursor: pointer;
}

.noti-global img {
  width: 18px;
}

.noti-global .count {
  position: absolute;
  display: inline-block;
  padding: 2px 3px;
  background-color: #da0606;
  font-size: 10px;
  color: #fff;
  border-radius: 2px;
  top: -5px;
  right: 15px;
}

.number-td-1202 {
  color: #f1c330;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.user-balance {
  padding: 5px 15px;
  margin-right: 15px;
  background: #436092;
  border-radius: 42px;
  color: #fff;
}

.user-ratio {
  margin-right: 15px;
  color: #fff;
}

.user-ratio img {
  margin-right: 7px;
}

/*// Ô seach đa chức năng*/
.seach-china {
  margin-left: 35px;
  width: 420px;
  height: 35px;
  border-radius: 25px;
  background-color: #fff;
  z-index: 100;
}

.select-mall-head {
  height: 35px;
  padding: 0px 10px 0px 20px;
  border-right: 1px solid #bfd5ec;
  cursor: pointer;
  position: relative;
  width: 120px;
}

.select-mall-head .image img {
  height: 22px;
  margin-right: 5px;
}

.seach-china input {
  width: 100%;
  height: 30px;
  border: none;
  font-size: 13px;
  color: #333;
  margin-right: 8px;
  outline: none;
}

.seach-china input::-webkit-input-placeholder {
  font-weight: 300;
  color: #bfd5ec;
  font-size: 13px;
  font-style: italic;
}

.seach-china input::-moz-placeholder {
  font-weight: 300;
  color: #bfd5ec;
  font-size: 13px;
  font-style: italic;
}

.seach-china input::-ms-input-placeholder {
  font-weight: 300;
  color: #bfd5ec;
  font-size: 13px;
  font-style: italic;
}

.seach-button {
  width: 80px;
}

.btn-18 {
  background: #fe6701;
  border: 1px solid #bfd5ec;
  border-radius: 20px;
  height: 35px;
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-18:hover {
  opacity: 0.8;
}

.sub-select-mall {
  position: absolute;
  min-height: 100px;
  background-color: #fff;
  width: 102px;
  left: 0px;
  top: 35px;
}

.sub-select-mall .image {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
}

.sub-select-mall .image:hover {
  background-color: #f2f2f2;
  transition: all 0.3s;
}

.add-border-99 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>

<style>
/* css thông báo KH */
.content-noti .el-dialog {
  width: 500px;
  position: absolute;
  right: 20px;
  top: -40px;
}

.content-noti .el-dialog__header {
  display: none;
}

.content-noti .el-dialog__body {
  padding: 0;
}

.content-noti .el-dialog__footer {
  display: none;
}

.header-noti {
  display: flex;
  align-items: center;
  height: 44px;
  color: #575757;
  padding: 0 20px;
  background: #F0F1F6;
}

.header-noti .header-left {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
}

.icon-left-noti {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: #F0F1F6;
  border-bottom: 1px solid #B4B4B4;
  cursor: pointer;
  position: relative;
}

.icon-left-noti img {
  width: 28px;
  height: 28px;
}

.icon-active {
  background: #28B8CE;
}

.content-right-noti {
  color: #575757;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.28px;
  border-bottom: 1px solid #B4B4B4;
  word-break: break-word;
  padding: 5px 8px 5px 16px;
  cursor: pointer;
}

.content-right-noti:hover {
  color: #F09920;
}

.content-right-noti:hover > .status-pack {
  color: #F09920;
}

.content-right-noti-active {
  background: #C9E1E9;
}

.content-right-noti-active:hover {
  color: #F09920;
}

.span19 {
  padding-left: 0 !important;
}

.content-noti .header-noti::before {
  content: "";
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  right: 110px;
  top: -14px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.count-noti {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DA100B;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 40px;
  font-size: 12px;
}

.loading-content {
  height: 424px;
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #bdd6ee;
}

::-webkit-scrollbar-thumb {
  border-radius: 22px;
  border: 1px solid #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

::-webkit-scrollbar {
  width: 5px;
}

.infinite-status-prompt {
  display: none;
}

.acticon-click {
  cursor: pointer;
}

.icon-seen {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}

.noti-none {
  height: 60px;
  display: flex;
}

.box-content-noti {
  display: flex;
  justify-content: space-between;
}

.txt-data {
  width: 410px;
}

.time-noti {
  font-weight: 700;
}

.status-pack {
  color: #F09920;
}

.list-category {
  border-right: 1px solid #bfd5ec;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.txt-dropdown {
  font-size: 12px;
  width: 100%;
}

/* // nav menu */
nav ul {
  display: flex;
  padding: 0;
  margin: 0;
}

nav > ul li {
  list-style: none;
}

nav > ul li {
  display: block;
  padding: 0 25px;
  line-height: 50px;
  text-decoration: none;
}

/*menu con*/
/*Ẩn các menu con cấp 1,2,3*/
nav li ul {
  display: none;
  min-width: 350px;
  position: absolute;
}

nav li > ul li {
  width: 100%;
  border: none;
  background: #fff;
  text-align: left;
}

.nav-lv {
  width: 220px;
}

.nav-lv:hover {
  background: #2196f3;
}

.nav-lv:hover > div > span {
  font-weight: 700;
  color: #fff;
}


nav li:hover > ul {
  display: block;
}

/*Hiển thị menu con cấp 2,3,4 bên cạnh phải*/
nav > ul li > ul li > ul {
  margin-left: 245px;
  margin-top: -48px;
}

.nav-menu-head .menu-lv-item {
  position: absolute;
  top: 44px;
  z-index: 9999;
}

.icon-down {
  padding: 0 10px;
}

.popupServiceChangeStaff .el-dialog__header {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.popupServiceChangeStaff .el-dialog__title {
  color: #1d60a6;
}

.popupServiceChangeStaff .el-textarea__inner {
  font-family: 'Helvetica Neue', sans-serif;
}

.popupServiceChangeStaff .el-dialog__body {
  padding-bottom: 0;
}

.popupServiceChangeStaff .el-dialog {
  max-width: 90%;
}

</style>

<style>
  @media screen and (min-device-width: 991.98px) and (max-device-width: 1200px) {
    .list_status_pack .status_item {
      width: 23%;
      padding: 5px 15px 5px 0;
      cursor: pointer;
    }
    .seach-china {
      width: 320px !important;
    }
    .user-ratio {
      margin-right: 5px !important;
      font-size: 12px !important;
    }
    .user-balance {
      padding: 5px 10px !important;
      margin-right: 10px !important;
    }
    .noti-global {
      padding-right: 15px !important;
    }
    .frame-btn-create-x-2022 {
      width: 45%;
    }
    .bottomTitle .content .text_1, .bottomTitle .content .text_2 {
      line-height: 17px;
    }
    .bottomTitle .content {
      padding: 20px 0 28px 22px;
    }
  }
</style>
