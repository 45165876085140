<template>
  <div class="rp-home" style="width: 100%">
    <div class="d-flex align-items-center justify-content-between">
      <div class="box-info-rp">
        <div class="d-flex align-items-center justify-content-between">
          <span class="txt_box">Đơn hàng</span>
          <img src="@/static/images/home/order.svg" alt="">
        </div>
        <div class="report-status" style="padding-top: 23px">
          <span class="label">Hoàn thành </span>
          <span class="num bg1">{{ orderClientPanel.status_4.total_order | vnd2 }}</span>
        </div>
        <div class="report-status">
          <span class="label">Đã đặt hàng</span>
          <span class="num bg2">{{ orderClientPanel.status_3.total_order | vnd2 }}</span>
        </div>
      </div>
      <div class="box-info-rp">
        <div class="d-flex align-items-center justify-content-between">
          <span class="txt_box">Kiện hàng</span>
          <img src="@/static/images/home/pack.svg" alt="">
        </div>
        <div class="report-status" style="padding-top: 23px">
          <span class="label">Đã nhận hàng</span>
          <span class="num bg1">{{ orderPackageClientPanel.status_16.total_order_package | vnd2 }}</span>
        </div>
        <div class="report-status">
          <span class="label">Đến kho Vn</span>
          <span class="num bg2">{{ orderPackageClientPanel.status_10.total_order_package | vnd2 }}</span>
        </div>
      </div>
      <div class="box-info-rp">
        <div class="d-flex align-items-center justify-content-between">
          <span class="txt_box">Khiếu nại</span>
          <img src="@/static/images/home/complain.svg" alt="">
        </div>
        <div class="report-status" style="padding-top: 23px">
          <span class="label">Đã xử lý</span>
          <span class="num bg1">{{ complainClientPanel.status_2.total | vnd2 }}</span>
        </div>
        <div class="report-status">
          <span class="label">Đang xử lý</span>
          <span class="num bg2">{{ complainClientPanel.status_1.total | vnd2 }}</span>
        </div>
      </div>
    </div>
    <!--    phần biểu đồ-->
    <div class="chart-report">
      <div class="header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <span class="title_chart">Báo cáo</span>
          <span class="type_chart" :class="typeChart === 'order' ? 'type_chart_active' : ''" @click="showChart('order')">Đơn hàng</span>
          <span class="type_chart" :class="typeChart === 'pack' ? 'type_chart_active' : ''" @click="showChart('pack')">Kiện hàng</span>
          <span class="type_chart" :class="typeChart === 'finance' ? 'type_chart_active' : ''" @click="showChart('finance')">Tài chính</span>
        </div>
        <div class="d-flex align-items-center select-time">
          <span class="day" :class="timeActive === 'date' ? 'time_active' : ''" @click="showTime('date')">Ngày</span>
          <span class="month" :class="timeActive === 'month' ? 'time_active' : ''" @click="showTime('month')">Tháng</span>
          <span class="year" :class="timeActive === 'year' ? 'time_active' : ''" @click="showTime('year')">Năm</span>
        </div>
      </div>
      <div class="body">
        <div v-if="typeChart === 'order'">
          <ReportOrder :time="timeActive"></ReportOrder>
        </div>
        <div v-if="typeChart === 'pack'">
          <ReportPack :time="timeActive"></ReportPack>
        </div>
        <div v-if="typeChart === 'finance'">
          <ReportFinance :time="timeActive"></ReportFinance>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import ReportOrder from "@/views/home/component/ReportOrder";
    import ReportPack from "@/views/home/component/ReportPack";
    import ReportFinance from "@/views/home/component/ReportFinance";
    import axios from "axios";
    export default {
        name: "ReportHome",
        metaInfo() {
            return {
                title: "",
            };
        },
        components: {
            ReportOrder,
            ReportPack,
            ReportFinance,
        },
        data() {
            return {
                canSubmit: true,
                timeActive: "date",
                typeChart: "order",
                orderClientPanel : [],
                orderPackageClientPanel : [],
                complainClientPanel : [],
            };
        },
        computed: {},
        mounted() {
            this.fetchOrderClientPanel();
            this.fetchOrderPackageClientPanel();
            this.fetchComplainClientPanel();
        },
        methods: {
            showChart(type) {
                this.typeChart = type;
            },
            showTime(val) {
                this.timeActive = val
            },
            fetchOrderClientPanel()
            {
              axios.get("report/report-order-client-panel").then((response) => {
                switch (response.code) {
                  case 200:
                    this.orderClientPanel = response.data;
                    break;
                  default:
                    this.$notify.error({
                      title: "Error",
                      message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                    });
                    break;
                }
              });
            },
            fetchOrderPackageClientPanel()
            {
              axios.get("report/report-order-package-client-panel").then((response) => {
                switch (response.code) {
                  case 200:
                    this.orderPackageClientPanel = response.data;
                    break;
                  default:
                    this.$notify.error({
                      title: "Error",
                      message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                    });
                    break;
                }
              });
            },
            fetchComplainClientPanel()
            {
              axios.get("report/report-complain-client-panel").then((response) => {
                switch (response.code) {
                  case 200:
                    this.complainClientPanel = response.data;
                    break;
                  default:
                    this.$notify.error({
                      title: "Error",
                      message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                    });
                    break;
                }
              });
            }
        },
        watch: {},
    };
</script>

<style lang="css" scoped>
  /*// phần biểu đồ*/
  .chart-report .title_chart {
    color: #104D84;
    font-size: 18px;
    font-weight: 500;
    margin-right: 30px;
  }
  .select-time span {
    width: 90px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #767676;
    border: 1px solid #DADADA;
    cursor: pointer;
  }
  .select-time .day {
    border-radius: 18px 0 0 18px;
    border-right: none;
  }
  .select-time .year {
    border-radius: 0 18px 18px 0;
    border-left: none;
  }
  .select-time .month {
    border-left: none;
    border-right: none;
  }
  .time_active {
    background: #CEE7FF !important;
    color: #104D84 !important;
  }
  .chart-report .type_chart {
    width: 100px;
    height: 30px;
    margin-right: 8px;
    background: #EFEFEF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #686868;
    border-radius: 3px;
  }
  .type_chart_active {
    color: #0F56B3 !important;
    background: #CEE7FF !important;
  }
  .box-info-rp {
    width: 28%;
    height: 140px;
    background: #fff;
    border-radius: 14px;
    padding: 20px;
  }
  .box-info-rp .txt_box {
    font-size: 18px;
    color: #104D84;
    font-weight: 500;
  }
  .report-status {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .report-status .label {
    color: #686868;
    font-size: 14px;
    font-weight: 500;
  }
  .report-status .num {
    height: 25px;
    min-width: 65px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .report-status .bg1{
    background: #07AF0E;
  }
  .report-status .bg2{
    background: #FFB80091;
    color: #104D84 !important;
  }
  .chart-report {
    background: #ffffff;
    width: 100%;
    min-height: 300px;
    border-radius: 14px;
    margin-top: 30px;
    padding: 20px 10px;
  }
</style>
